
	.admin-content {
		* { box-sizing: border-box; }
		display: flex;
		width: 1200px;
		margin: 10px auto 0px;
		height: calc(100% - 75px);
		.left-wrapper {
			width: 240px;
			background-color: #FFFFFF;
			border-radius: 4px;
			height: 100%;
			margin-right: 20px;
		}
		.main-wrapper {
			flex: 1;
			height: 100%;
			background-color: #FFFFFF;
			border-radius: 4px;
			
		}
	}
    .nowrap-scrollbar-x {
        ::v-deep .el-scrollbar__wrap {
            overflow-x: hidden;
        }
    }
    .addPaper-left{
        display: flex;
        align-items: center;
        padding: 10px 0;
        margin-left: 10px;
    }
    .el-tree-left{
        height: calc(100% - 60px);
        overflow: auto;
    }
    ul,li{
        list-style: none;
        padding: 0;
        margin: 0;
    }
    .addPaper-step{
        display: flex;
        align-items:center;
        justify-content: start;
        padding:20px 10px;
        border-bottom: 2px solid #409eff;
        .el-steps--simple{
            flex: 1;
            width: 1%;
        }
        &:hover span{
            color: #409eff;
            cursor: pointer;
        }
    }
    .addPaper-content{
        height: calc(100% - 88px);
        .addPaper-question-type{
            display: flex;
            display: -webkit-flex;
            justify-content: start;
            -webkit-justify-content: flex-start;
            -webkit-align-content: center;
            align-items: center;
            width: 100%;
            .question-type-title{
                display: block;
                height: 18px;
                font-size: 14px;
                font-weight: 400;
                color: #333;
                margin: 10px 10px;
            }
            .question-type-tab{
                display: flex;
                display: -webkit-flex;
                justify-content: start;
                -webkit-justify-content: flex-start;
                -webkit-align-content: center;
                align-items: center;
                li{
                    cursor: pointer;
                    width:62px;
                    height: 30px;
                    line-height: 30px;
                    text-align: center;
                }
                .addPaperActive{
                    background: #409eff;
                    border-radius: 4px;
                    color: #fff;
                }
            }
        }
        .addPaper-item{
            background: #f5f9ff;
            height: 50px;
            line-height: 50px;
            margin: 0 10px;
            display: flex;
            display: -webkit-flex;
            justify-content: space-between;
            -webkit-justify-content: space-between;
            font-size: 12px;
            cursor: pointer;
            .addPaper-item-left{
                display: flex;
                display: -webkit-flex;
                align-items: center;
                -webkit-align-items: center;
                span{
                    border: 1px solid #ddd;
                    padding: 0 10px;
                    height: 25px;
                    line-height: 25px;
                    border-radius: 2px;
                    display: inline-block;
                }
                .active{
                    background: #409eff;
                    color: #fff;
                }
                .addPaper-search{
                    margin-left: 10px;
                    .addPaper-search-input{
                        height: 30px;
                        line-height: 30px;
                        .el-input__inner{
                            height: 30px;
                            line-height: 30px;
                        }
                    }
                }
            }
           .addPaper-item-right{
               display: flex;
               display: -webkit-flex;
               align-items: center;
               -webkit-align-items: center;
               .checkbox-answer{
                   margin-right:10px;
               }
               .total-title{
                   margin-right:10px;
                   .text-num {
                       color: #409eff;
                   }
               }
               .addPaper-BtnAll{
                   padding:8px 4px;
                   font-size: 12px;
                   color: #fff;
                   border: none;
               }
           }
        }
        .addPaper-typeBox{
            padding: 10px;
            overflow-y: auto;
            height: calc(100% - 190px);
        }
        .addPaper-PreviewBox{
            display: -webkit-box;
            display: flex;
            -webkit-box-pack: justify;
            justify-content: space-between;
            height: 100%;
            .timeline-preview{
                padding-bottom: 20px;
            }
        }
        .addPaper-Preview{
            display: -webkit-box;
            display: flex;
            -ms-flex-direction: column;
            flex-direction: column;
            font-size: 14px;
            flex: 1;
            padding-right: 28px;
            .addPaper-review-top{
                display: -webkit-box;
                display: flex;
                justify-content: start;
                -webkit-justify-content: flex-start;
                -webkit-box-align: center;
                align-items: center;
                margin: 10px 0;
                .addPaper-review-label{
                    display: block;
                    font-size: 14px;
                    font-weight: 400;
                    color: #333;
                    margin: 10px 10px;
                    .testPaper-title{
                        margin-right: 10px;
                    }
                }
            }
            .Examination-paper-date{
                height:40px;
                background: #fef7d7;
                border: 1px solid #fda43c;
                line-height:40px;
                margin: 10px 0 0 10px;
                input{
                    width: calc(100% - 4px);
                    font-size: 22px;
                    line-height: 38px;
                    text-align: center;
                    border: none;
                }
            }
            .Test-paper-box{
                height: calc(100% - 102px);
                overflow: auto;
                ::v-deep .el-scrollbar__wrap {
                    overflow-x: hidden;
                }
            }
        }
        .addPaper-save {
            margin-top: 10px;
            height: 100%;
            .save-paper-date {
                font-size: 22px;
                font-weight: 700;
                color: #333;
                display: -webkit-box;
                display: flex;
                justify-content: center;
                padding: 0 0 10px 0;
                height: 32px;
            }
            .addPaper-save-table {
                .addPaper-content {
                    height: 100%;
                }
                display: flex;
                flex-direction: column;
                padding: 10px 10px 0;
                height: calc(100% - 50px);
                .thead {
                    display: flex;
                    display: -webkit-flex;
                    width: 100%;
                    height: 50px;
                    background: #409eff;
                    line-height: 50px;
                    margin: 0 auto;
                    color: #fff;
                    div {
                        font-weight: 700;
                        font-size: 14px;
                    }
                }
                .tbody {
                    display: flex;
                    display: -webkit-flex;
                    flex-direction: column;
                    -webkit-flex-direction: column;
                    .type-title {
                        margin: 0;
                        padding: 0 25px;
                        text-align: left;
                        font-size: 14px;
                        font-weight: 400;
                        color: #333;
                        height: 40px;
                        line-height: 40px;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                    }
                    .save-score {
                        text-align: right;
                        padding-right: 10px;
                        color: #333;
                        .input {
                            width: 56px;
                            text-align: center;
                            margin-right: 2px;
                        }
                    }
                    .save-paper-content {
                        .topic-title {
                            padding-right: 10px;
                        }
                        display: flex;
                        display: -webkit-flex;
                        justify-content: start;
                        -webkit-justify-content: flex-start;
                        align-items: center;
                        -webkit-align-items: center;
                        padding: 10px 0;
                        box-sizing: border-box;
                    }
                }
                .save-item{
                    height: 650px;
                    overflow: auto;
                    ::v-deep .el-scrollbar__wrap {
                        overflow-x: hidden;
                    }
                }
                .save-item-scBox {
                    /*height: 568px;*/
                    overflow: auto;
                    /*border: 1px solid #eaeaea;*/
                    border-bottom: none;
                }
                .addPaper-save-foot {
                    height: 50px;
                    line-height: 50px;
                    background: #f5f9ff;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                .Serial-number {
                    text-align: center;
                	width: 80px;
                }
                .topic-title {
                	flex: 1;
                }
                .topic-tags {
                	width: 200px;
                }
                .Set-points {
                	width: 105px;
                    &.total-score {
                        width: 150px;
                    }
                }
            }
        }
    }
