
.Test-border{
    display: -webkit-box;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    text-align: left;
    padding: 0 30px 0 6px;
    border: 1px solid transparent;
    flex: 1;
    position: relative;
    .serial-number{
        float: left;
    }
    .Test-paper-title{
        padding: 15px 4px;
        font-weight: 700;
        margin: 0;
    }
    .Test-paper-op{
        position: absolute;
        right: -30px;
        top:10px;
        display: none;
        cursor: pointer;
        .anticon{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 30px;
            height: 30px;
            background: #ddd;
            color: #fff;
            border-radius: 2px;
        }
        .Anticonblue{
            &.move-item {
                margin-bottom: 5px;
            }
            background: #409eff;
            color: #fff;
        }
        .Anticondel{
            background: #f56c6c;
            color: #fff;
        }
    }
    .Test-paper-test{
        color: #333;
        padding-left: 10px;
        li{
            margin-bottom: 10px;
            list-style: none;
        }
    }
    .Test-border-border{
        position: relative;
        border: 1px solid transparent;
    }
    .Test-border-hover:hover .Test-paper-op {
        display: block;
    }
    .Test-border-hover:hover .Test-border-border {
        border: 1px solid #409eff;
    }
    .Test-paper-topic {
        font-weight: 700;
        margin: 0;
        line-height: 2;
    }
}
