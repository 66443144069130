
ul,li{
    list-style: none;
    margin: 0;
    padding: 0;
}
::v-deep .html-span{
    p{
        padding: 0;
        margin: 0;
    }
}
.questiontype-content{
    border: 1px solid #deedff;
    font-size: 14px;
    color: #333;
    margin-bottom: 10px;
    .questiontype-top{
        display: flex;
        display: -webkit-flex;
        font-size: 12px;
        color: #333;
        font-weight: 700;
        padding: 4px 4px;
        .Title{
            display: -webkit-box;
            display: flex;
            font-size: 12px;
            color: #333;
            font-weight: 700;
            padding: 4px 4px;
            align-items: center;
            -webkit-align-items: center;

            .Title-span{
                min-width:36px;
                display: inline-block;
            }
        }
    }
    .answer-analysis{
        display: flex;
        display: -webkit-flex;
        flex-direction: column;
        -webkit-flex-direction: column;
        margin-bottom: 10px;
        .answer-analysis-top{
            font-size: 12px;
            margin:10px 0 0 10px;
        }
        .answer-analysis-label{
            color: #409eff;
        }
        .answer-analysis-text{
            display: inline-block;
            margin-left: 10px;
            font-size: 12px;
        }
        .answer-analysis-bottom{
            font-size: 12px;
            margin:10px 0 0 10px;
        }
    }
    .answer-analysis-foot{
        display: flex;
        display: -webkit-flex;
        justify-content: start;
        -webkit-justify-content: flex-start;
        background: #f5f9ff;
        height: 40px;
        line-height: 40px;
        padding-left: 10px;
        margin-top: 25px;
        font-size: 12px;
        font-weight: 400;
        .answer-analysis-foot-left{
            display: flex;
            display: -webkit-flex;
            flex: 1;
            width: 1%;
            span{
                display: inline-block;
                margin-right: 10px;
            }
        }
        .add-analysisBtn{
            padding:6px 4px;
            margin-right:10px;
        }
        .remove-analysisBtn{
            background-color:#F56C6C;
            border-color: #F56C6C;
            padding:6px 4px;
            margin-right:10px;
        }
    }

}
.null-data{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size:14px;
    height: 558px;
}
