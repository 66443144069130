
.selectedStep-top{
    height:88px;
    line-height:88px;
    border-bottom: 2px solid #409EFF;
    display: -webkit-box;
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    -webkit-box-align: center;
    align-items: center;
    .Pre{
        display: flex;
        display: -webkit-flex;
        -webkit-box-direction: normal;
        flex-direction: column;
        font-size: 12px;
        cursor: pointer;
        i{
            height: 20px;
            line-height: 20px;
            font-size: 30px;
            margin-right: 6px;
            -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
        }
        span {
            line-height: 30px;
            height: 30px;
            display: inline-block;
        }
    }
    .activeStep{
        color: #409eff;
    }
    .step-content{
        display: flex;
        display: -webkit-flex;
        -webkit-box-pack: start;
        justify-content: flex-start;
        -webkit-box-align: center;
        align-items: center;
        .step-border {
            height: 40px;
            line-height: 40px;
            background: #ddd;
            color: #fff;
            padding: 0 10px;
            border-radius: 4px;
            cursor: pointer;
            i{
                padding-right: 10px;
            }
        }
        .blue{
            background: #409eff;
            color: #fff;
        }
        .blue+.setp-line {
            background-image: url(../../assets/img/image/blue-line.png);
            height: 10px;
            margin-left: 10px;
            width: 162px;
            background-repeat: no-repeat;
        }
        .setp-line {
            background-image: url(../../assets/img/image/hui-line.png);
            height: 10px;;
            margin-left: 10px;
            width: 162px;
            background-repeat: no-repeat;
        }
    }
    .Next{
        display: flex;
        display: -webkit-flex;
        -webkit-box-direction: normal;
        flex-direction: column;
        font-size: 12px;
        cursor: pointer;
        i{
            height: 20px;
            line-height: 20px;
            font-size: 30px;
            margin-left: 6px;
        }
        span {
            line-height: 30px;
            height: 30px;
            display: inline-block;
        }
    }
}
